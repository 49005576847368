import * as React from "react";
import * as style from "./Home.style";
import * as Components from "../../components";
import { IKImage } from "imagekitio-react";

const Home: React.FC = () => {
  return (
    <>
      <div className={style.component}>
        <div className={style.titleImageWrapper}>
          <IKImage
            path="/mcgregor-fitness-logo.png"
            className={style.titleImage}
          />
        </div>
        <div className={style.titleSection}>
          <div className={style.title}>Website coming soon...</div>
          <div className={style.subTitle}>
            ...but for now, call or text me at 302-508-0241 for info on personal
            training, group training, or remote training via Zoom or the
            McGregor Fitness training app!
          </div>
        </div>
      </div>
    </>
  );
};

Home.displayName = "Home";
export { Home };
