// prettier-ignore
import * as React from "react";

import {
  Route,
  BrowserRouter,
  useLocation,
  Routes,
  Navigate,
} from "react-router-dom";
import { Header, Footer } from "../components";
import { IKContext } from "imagekitio-react";
import { DisplayContextProvider } from "./DisplayContext";
import { useSpring, animated } from "react-spring";
import { style as s } from "typestyle";
import "../../favicon.ico";
import { Home } from "../pages";

const component = s({
  width: "100%",
  height: "100%",
  zIndex: 1,
  overflow: "hidden",
  // margin: -8,
});

const Page: React.FC = () => {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div className={component}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </div>
  );
};

export const App = () => {
  const spring = useSpring({ to: { opacity: 1 }, from: { opacity: 0 } });

  return (
    <animated.div style={{ ...spring, position: "relative" }}>
      <BrowserRouter>
        <DisplayContextProvider>
          <IKContext urlEndpoint="https://ik.imagekit.io/yd113v8urnw4/McGregor">
            <Page />
          </IKContext>
        </DisplayContextProvider>
      </BrowserRouter>
    </animated.div>
  );
};
