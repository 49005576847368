import { media, style as s } from "typestyle";
import * as Style from "../../styles";

export const component = s({
  fontFamily: "Poppins",
});

export const titleImageWrapper = s(
  {
    margin: "auto",
    width: "90%",
    marginBottom: 20,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      width: "50%",
      margin: "-6% auto",
    },
  ),
);

export const titleImage = s({
  width: "100%",
});

export const titleSection = s(
  {
    padding: `10px 20px`,
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      padding: `10px 80px`,
    },
  ),
);

export const title = s({
  fontSize: 36,
  fontWeight: 300,
  marginBottom: 25,
  textTransform: "uppercase",
});

export const subTitle = s(
  {
    fontSize: 18,
    fontWeight: 700,
  },
  media({ minWidth: Style.screensize.mdMin }, { fontSize: 24 }),
);
